.HH-NavBar {
    background-color: #BFBFB8;
    height: 85px;
    display: flex;
    justify-content: space-between;
    z-index: 12;
}

.HH-NavLink {
    color: #35403A;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
}

#Nav-Logo {
    height: 50px;
    width: 100%;
    float: right;
    background-repeat: no-repeat;
    background-position: center right;
}  

@media screen and (max-width: 767px) {   
    #Nav-Logo{
        background-image: url(../../assets/nav-mobile-logo.svg);
    } 
}

@media screen and (min-width: 768px) {      
    #Nav-Logo{      
        background-image: url(../../assets/nav-mobile-logo.svg);
    } 
} 
@media (min-width: 992px) {      
    #Nav-Logo{        
        background-image: url(../../assets/nav-logo.svg);
    } 
}
@media (min-width: 1200px) {    
    #Nav-Logo{         
        background-image: url(../../assets/nav-logo.svg);
    } 
}
