body {
  margin: 0;
}

@font-face {
  font-family: "AvenirNext-UltraLight";
  src: local("AvenirNext-UltraLight"),
    url("./fonts/AvenirNext-UltraLight/AvenirNext-UltraLight.ttf") format("truetype");
  font-weight: bold;
}
